import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import {  TitleH2, Section, Text } from "../../components/Core";
import { device } from "../../utils";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#F7F7FB"
      className="position-relative"
      py={["50px", null, "130px", null, "230px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className=" position-static order-lg-2">
            <ImgContainer className=" pl-lg-5">

            </ImgContainer>
          </Col>
          <Col lg="10" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <TitleH2>
                  Features of Healthcare Maintenance Management Software
                </TitleH2>
                <Text mb={5}>
                  From patient needs and rooms, to operating theatres and public
                  areas, maintenance management software is an excellent way to
                  keep track of, manage, and resolve your healthcare facility’s
                  health and maintenance requirements.
                </Text>
                <Text color="secondary">
                  With this type of cloud-based management software, you can:
                </Text>

                <ULStyled>
                  <li> Instantly resolve equipment and facility faults</li>
                  <li>
                    {" "}
                    Manage all equipment and janitorial tasks in one place
                  </li>
                  <li>
                    {" "}
                    Stay informed about all completed and pending maintenance
                    practices
                  </li>
                  <li>
                    {" "}
                    Comply with all health and safety regulations with documents
                    stored on the system
                  </li>
                  <li>
                    {" "}
                    Assign staff to prioritized tasks for the day, week, month,
                    and beyond
                  </li>
                </ULStyled>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
