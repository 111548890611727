import React from "react";
import styled from "styled-components";
import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

const SectionStyled = styled(Section)`
  background-image: ${({ theme }) => `radial-gradient(
    circle 961px at 49.94% 0%,
    ${lighten(0.114, saturate(0.0911, theme.colors.ash))} 0%,
    ${theme.colors.ash} 100%
  );`};
`;

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 0;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 position-static">
              <ImgRight>

              </ImgRight>
            </Col>
            <Col md="10" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" color="light">
                    Healthcare Maintenance Management Software
                    <Text mb={4} color="white">
                      Safeguard Your Community
                    </Text>
                  </Title>
                  <Text mb={4} color="light" opacity={0.7}>
                    Your patients and medical staff are your number one priority
                    at all times, which is why keeping them safe is of absolute
                    importance. But this can be difficult with viruses and
                    bacteria looming around and putting so many people in the
                    healthcare facility at risk. This, however, is manageable to
                    some extent. <br className="d-none d-md-block" />
                    Although it’s impossible to eliminate germs and pathogens
                    entirely, it is possible to maintain the highest standards
                    of hygiene and maintenance. It’s only natural that managing
                    an entire medical facility’s maintenance is extremely
                    challenging – especially with so many people walking in and
                    out of your facility on a daily basis
                    <br className="d-none d-md-block" />
                    Luckily, healthcare maintenance management software is a
                    valuable tool to easily manage your maintenance team and
                    tasks.
                  </Text>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
