import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { TitleH4, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <TitleH4>Why Napa Vertical is Your Lifesaver</TitleH4>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Text variant="small" my={4}>
                Everyone needs a lifesaver every once in a while. While you and
                your staff are out there physically saving lives, it’s good to
                know there is a certain helping hand that has your back too.
                Napa Vertical understands that a simple equipment malfunction
                could mean the difference between life and death, which is why
                we’ve created our cloud-based management software to uniquely
                match your high-performance requirements.!
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
